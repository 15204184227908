// import Vue from "vue";
// import App from "./App.vue";
// import VueIntercom from "vue-intercom";
// import VueRollbar from "vue-rollbar";
// import router from "./router.js";
// import store from "./store";
// import "./assets/tailwind.css";
// import "./assets/app.css";
// import "./assets/layout/main.scss";
// import "./assets/qlik/qlik.css";
// import { createProvider } from "./plugins/vue-apollo.js";
// import "./plugins/element.js";
// import "./plugins/couchdb.js";
// import "./plugins/fontAwesome.js";
// import "./plugins/mixpanel.js";
// import "./plugins/gTag.js";
// import "./filters/date.js";
// const {
//   VUE_APP_INTERCOM_APP_ID,
//   VUE_APP_ROLLBAR_TOKEN,
//   NODE_ENV
// } = process.env;

// Vue.config.productionTip = false;

// if (NODE_ENV === "production") {
//   Vue.use(VueIntercom, { appId: VUE_APP_INTERCOM_APP_ID });
//   Vue.use(VueRollbar, {
//     accessToken: VUE_APP_ROLLBAR_TOKEN,
//     captureUncaught: true,
//     captureUnhandledRejections: true,
//     enabled: true,
//     source_map_enabled: true,
//     environment: NODE_ENV
//   });

//   Vue.config.errorHandler = Vue.rollbar.error;
// }

// export default new Vue({
//   router,
//   store,
//   apolloProvider: createProvider(),
//   render: h => h(App)
// }).$mount("#app");

window.location.href = "https://app.delight-data.com/ignition";
